


import {mapGetters} from 'vuex';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

type TFormFields = {
  name: string;
  surname: string;
  company_name: string;
  company_position: string;
  email: string;
}

type TFormFieldsRequired = {
  name: string;
  surname: string;
  company_name: string;
  email: string;
}

type TPayloadFields = {
  name?: string;
  surname?: string;
  company_name?: string;
  company_position?: string;
  email?: string;
  event_id?: string;
}

type TFieldTexts = {
  [key: string]: TranslateResult;
}

type TFormErrors = {
  name?: TranslateResult;
  lastName?: TranslateResult;
  companyName?: TranslateResult;
  jobPosition?: TranslateResult;
  email?: TranslateResult;
}

interface IContactInfoInviterData {
  formFields: TFormFields;
  formFieldsRequired: TFormFieldsRequired;
  formFieldsLabelTranslationKeys: TFieldTexts;
  formFieldsErrors: TFieldTexts;
  isSavingInProgress: boolean;
}

interface IContactInfoInviterMethods {
  prefillFormFields: () => void;
  submitHandler: () => Promise<void>;
  clearErrorIfNotEmpty: (key: string) => void;
  startProgressIndication: () => void;
  endProgressIndication: () => void;
  onAddMoreInfoClick: () => Promise<void>;
  clearPreviousErrors: () => void;
  proceedToEditForm: () => void;
}

interface IContactInfoInviterComputed {
  eventId: string;
  isAuthenticated: boolean;
  contactInfoRequested: boolean;
  contactInfo: any;
  isAllFilledRequired: boolean;
  isPageEditForm: boolean;
  isPopupShown: boolean;
}

const ContactInfoInviter = Vue.extend<IContactInfoInviterData, IContactInfoInviterMethods, IContactInfoInviterComputed>({
  name: 'contact-info-inviter',
  components: {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      contactInfoRequested: 'contactStore/contactInfoRequested',
      contactInfo: 'contactStore/contactInfo',
    }),
    eventId: function (): string {
      return this.$route.params.eventId;
    },
    isAllFilledRequired: function (): boolean {
      this.prefillFormFields();
      for (const i in this.formFieldsRequired) {
        if (!this.contactInfo[i as keyof TFormFieldsRequired]) {
          return false;
        }
      }
      return true;
    },
    isPageEditForm: function (): boolean {
      switch (this.$route.name) {
        case 'promo-page-edit':
          return true;
        case 'promo-page-edit-contact':
          return true;
        default:
          return false;
      }
    },
    isPopupShown: function (): boolean {
      if (this.contactInfoRequested && !this.isAllFilledRequired && !this.isPageEditForm) {
        return true;
      }
      return false;
    }
  },
  watch: {
    contactInfo: {
      deep: true,
      handler(): void {
        this.prefillFormFields();
      }
    },
  },
  data(): IContactInfoInviterData {
    return {
      formFieldsRequired: { // These keys will be sent to backend
        name: '',
        surname: '',
        company_name: '',
        email: '',
      },
      formFields: { // These keys will be sent to backend
        name: '',
        surname: '',
        company_name: '',
        company_position: '',
        email: '',
      },
      formFieldsLabelTranslationKeys: { // We keep translation keys in camel case, so they are separate here
        name: 'name',
        surname: 'lastName',
        company_name: 'companyName',
        company_position: 'jobPosition',
        email: 'email',
      },
      formFieldsErrors: { // Error texts are reactive. Displayed if they are truey.
        name: '',
        lastName: '',
        companyName: '',
        jobPosition: '',
        email: '',
      },
      isSavingInProgress: false,
    };
  },
  methods: {

    proceedToEditForm(): void {
      this.$router.push({
        name: 'promo-page-edit'
      });
    },

    clearPreviousErrors(): void {
      for (const i in this.formFieldsErrors) {
        this.formFieldsErrors[i] = '';
      }
    },

    /* If the contact has some data already, show it in the popup */
    prefillFormFields(): void {
      for (const i in this.formFields) {
        this.formFields[i as keyof TFormFields] = this.contactInfo[i as keyof TFormFields];
      }
    },

    /* Removes the error state from a field if it is not empty */
    clearErrorIfNotEmpty(key: string): void {
      if (this.formFields[key as keyof TFormFields] !== '') {
        this.formFieldsErrors[(this.formFieldsLabelTranslationKeys[key] as string)] = '';
      }
    },

    /* Handles form submission */
    async submitHandler(): Promise<void> {

      // TODO: rethink the logic of required fields vs.close the popup:
      // - when user has filled nothing, maybe it is fine to let user browse away?
      // - when user filled smth but hasn't filled the name, require name since it is required by backend

      let errorCount = 0;

      this.clearPreviousErrors();

      // check for new errors, currently checking only for name since only name is required by backend
      if (!this.formFields.name) {
        errorCount++;
        this.formFieldsErrors.name = this.$t('contacts.popups.contactInfoInviter.fieldErrors.name');
      }

      if (!this.formFields.surname) {
        errorCount++;
        this.formFieldsErrors.lastName = this.$t('contacts.popups.contactInfoInviter.fieldErrors.lastName');
      }

      if (!this.formFields.company_name) {
        errorCount++;
        this.formFieldsErrors.companyName = this.$t('contacts.popups.contactInfoInviter.fieldErrors.companyName');
      }

      if (!this.formFields.email) {
        errorCount++;
        this.formFieldsErrors.email = this.$t('contacts.popups.contactInfoInviter.fieldErrors.email');
      }

      if (errorCount === 0) {

        let payloadCount = 0;

        const payload: TPayloadFields = {};
        for (const i in this.formFields) {
          if (this.formFields[i as keyof TFormFields] !== '') {
            payloadCount++;
            payload[i as keyof TFormFields] = this.formFields[i as keyof TFormFields];
          }
        }

        if (!payloadCount) {
          // Nothing to send on submit? Close popup, let the user browse.
          return;
        }

        payload.event_id = this.eventId;

        this.startProgressIndication();
        await this.$store.dispatch('contactStore/editContact', payload);
        this.endProgressIndication();
      }

      await this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);
    },

    startProgressIndication(): void {
      this.isSavingInProgress = true;
    },

    endProgressIndication(): void {
      this.isSavingInProgress = false;
    },

    /* If some info has been entered,
     * save it before route change
     * TODO: temporarily save into store without passing to backend when there is no payload.name
     */
    async onAddMoreInfoClick(): Promise<void> {

      let payloadCount = 0;

      const payload: TPayloadFields = {};
      for (const i in this.formFields) {
        if (this.formFields[i as keyof TFormFields] !== '') {
          payloadCount++;
          payload[i as keyof TFormFields] = this.formFields[i as keyof TFormFields];
        }
      }

      if (!payloadCount || !payload.name) {
        this.proceedToEditForm();
        return;
      }

      payload.event_id = this.eventId;

      try {
        await this.$store.dispatch('contactStore/editContact', payload);
        await this.$store.dispatch('contactStore/callContact');
      } finally {
        this.proceedToEditForm();
      }
    },
  }
});
export default ContactInfoInviter;
