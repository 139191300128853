


import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
// TODO: return promoSideBar and promoHead
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import promoHead from '@/views/components/promoPage/head.vue';

import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';

enum StatisticsTab {
  PERSONAL = 'personal',
  COMPANY = 'company',
  EVENT = 'event',
}

type TStatisticsData = {
  StatisticsTab: typeof StatisticsTab;
}

type TStatisticsMethods = {
}

type TStatisticsComputed = {
  eventInfo: TEvent;
  eventId: number;
  currentTab: StatisticsTab;
  canSeeEventStatistics: boolean;
}

// TODO: add IStatisticsComputed interface
// TODO: add IMethods interface

const Statistics = Vue.extend<TStatisticsData, TStatisticsMethods, TStatisticsComputed>({
  name: 'statistics',
  components: { PromoSideBarLeft, PromoSideBarRight, promoHead, ContactInfoInviter },
  computed: {
    ...mapGetters('_eventStore', {
      eventInfo: 'event',
    }),
    eventId(): number {
      return Number(this.$route.params.eventId);
    },
    currentTab(): StatisticsTab {
      switch (this.$route.name) {
        case 'result-company':
          return StatisticsTab.COMPANY;

        case 'result-event':
          return StatisticsTab.EVENT;

        case 'result-personal':
        default:
          return StatisticsTab.PERSONAL;
      }
    },
    canSeeEventStatistics(): boolean {
      return this.eventInfo && this.eventInfo.personal.is_creator;
    }
  },
  created(): void {
    this.$store.dispatch('contactStore/callContact', this.eventId);
  },
  data(): TStatisticsData {
    return {
      StatisticsTab: StatisticsTab,
    };
  },
});

export default Statistics;
